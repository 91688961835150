import React from "react";
// import OwlCarousel from "react-owl-carousel";

const Companies = () => {
  // var settings = {
  //   items: 2,
  //   margin: 25,
  //   dots: false,
  //   nav: true,
  //   loop: true,
  //   autoplay: true, // Enable auto-scrolling
  //   autoplayTimeout: 3000, // Time in milliseconds between slides
  //   responsiveClass: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     768: {
  //       items: 3,
  //     },
  //     1170: {
  //       items: 6,
  //     },
  //   },
  // };

  return (
    // <OwlCarousel
    //   {...settings}
    //   className="lead-group-slider owl-carousel owl-theme"
    // >
    //   <div className="card-body item">
    //     <div className="lead-img">
    //       <img className="img-fluid" alt="" src="https://idfy-eia3.vercel.app/assets/logo1-D7AqdC-G.jpg"/>
    //     </div>
    //   </div>
    //   <div className="card-body item">
    //     <div className="lead-img">
    //       <img className="img-fluid" alt="" src="https://idfy-eia3.vercel.app/assets/logo2-Bjhd8dXy.png" />
    //     </div>
    //   </div>
    //   <div className="card-body item">
    //     <div className="lead-img">
    //       <img className="img-fluid" alt="" src="https://idfy-eia3.vercel.app/assets/log3-D3L7OafR.png" />
    //     </div>
    //   </div>
    //   <div className="item card-body">
    //     <div className="lead-img">
    //       <img className="img-fluid" alt="" src="https://idfy-eia3.vercel.app/assets/logo4-CmX0GcRG.png" />
    //     </div>
    //   </div>
    //   <div className="item card-body">
    //     <div className="lead-img">
    //       <img className="img-fluid" alt="" src="https://idfy-eia3.vercel.app/assets/logo5-DddCxhI1.jpg"/>
    //     </div>
    //   </div>
    //   <div className="item card-body">
    //     <div className="lead-img">
    //       <img className="img-fluid" alt="" src="https://idfy-eia3.vercel.app/assets/logo6-BaryiB03.png" />
    //     </div>
    //   </div>
    //   <div className="item card-body">
    //     <div className="lead-img">
    //       <img className="img-fluid" alt="" src="https://idfy-eia3.vercel.app/assets/logo7-Bp2tKP0k.png" />
    //     </div>
    //   </div>
    // </OwlCarousel>
    <>
    </>
  );
};

export default Companies;
