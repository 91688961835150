
import React from "react";

import 'react-toastify/dist/ReactToastify.css';

import EditCourse2 from "../addCourse/EditCourse2";


const EditCourse = () => {
  
  return (
    <EditCourse2 />
  );
};

export default EditCourse;